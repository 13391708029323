import React from "react";
import { graphql } from "gatsby";
import Helmet from 'react-helmet';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "@assets/scss/App.scss";
import "./assets/jobDetails.scss";

import Layout from "@components/layout";
import Header from "@components/header/Header";
import PopularSearch from "@components/popular-search/index";
import Footer from "@components/footer/";
import SEO from "@components/seo";

import OnlyArticleTextHTML from "@components/article-text/only-article-text-html";
import ContactSidebar from "@components/modules/contact-sidebar/";
import {
    JobLocation,
    JobSalary,
    JobOTE,
    JobType,
    JobDate
} from "@components/jobs/results/job/functions";
import ImageTextModule from "@components/modules/image-text/";
import BackToolbar from "@components/modules/back-toolbar/";

// import SaveIcon from '@icons/heart.inline.svg';

const JobPages = ({pageContext, data, location}, props) => {
    let headerClass;
    const sidebarData = [
        {
            hideModuleBg: false,
            hideSaveBtn: false,
            sidebarVideo: {
                videoask: true,
                posterImage: data.sidebarImg
            },
            sidebarButtons: [
                {
                    Label: "Apply for this Job",
                    page: {
                        Slug: "apply-for-job",
                        btnState: {
                            title: data.strapiJobs.title,
                            jobUrl: location.href
                        }
                    },
                }
            ]
        }
    ]

    return (
        <Layout>
            <Helmet 
                bodyAttributes={{
                    class: 'grey-bg job-details'
                }}
            />
            <SEO
                title={`${data.strapiJobs.title} ${data.strapiJobs.websitecountry}, ${data.strapiJobs.address.countryCode}`}
                description={`Looking for a ${data.strapiJobs.title} job near ${data.strapiJobs.websitecountry}, ${data.strapiJobs.address.countryCode}?  We have the ideal position with.  Visit Crux Careers, recruitment specialists in the UK.`}
            />
            <Header classNameHeader={headerClass} />
            <BackToolbar
                backLinkLabel=" Back to Job Listings"
                showCreateAlert={false}
            />
            <Container>
                <Row id="sidebar-end-scroll" className="pb-40 pb-md-60 pb-xl-0">
                    <Col xs={12}>
                        {data.strapiJobs.title &&
                            <h1 className="h5 job__title mt-34 mb-15 mt-md-48 mb-md-24 mt-xl-72 mb-xl-42">
                                {data.strapiJobs.title}
                                {/* <SaveIcon className="icon-heart" /> */}
                            </h1>
                        }
                    </Col>
                    <Col xs={12} xl={7}>
                        <div className="job__info-list">
                            {data.strapiJobs.websitecountry &&
                            <div className="job__info-list__item pb-11 pb-md-10 mb-12 mb-md-9">
                                <div className="job__info-list__item__title font-weight-bold">Location</div>
                                <div className="job__info-list__item__text"><JobLocation jobData={data.strapiJobs} /></div>
                            </div>
                            }
                            {data.strapiJobs.salary !== 0 &&
                            <div className="job__info-list__item pb-11 pb-md-10 mb-12 mb-md-9">
                                <div className="job__info-list__item__title font-weight-bold">Salary</div>
                                <div className="job__info-list__item__text"><JobSalary jobData={data.strapiJobs} /></div>
                            </div>
                            }
                            {data.strapiJobs.ote !== 0 &&
                            <div className="job__info-list__item pb-11 pb-md-10 mb-12 mb-md-9">
                                <div className="job__info-list__item__title font-weight-bold">OTE</div>
                                <div className="job__info-list__item__text"><JobOTE jobData={data.strapiJobs} /></div>
                            </div>
                            }
                            {data.strapiJobs.job_type &&
                            <div className="job__info-list__item pb-11 pb-md-10 mb-12 mb-md-9">
                                <div className="job__info-list__item__title font-weight-bold">Job Type</div>
                                <div className="job__info-list__item__text"><JobType jobData={data.strapiJobs} /></div>
                            </div>
                            }
                            {data.strapiJobs.createdAt &&
                            <div className="job__info-list__item pb-11 pb-md-10 mb-12 mb-md-9">
                                <div className="job__info-list__item__title font-weight-bold">Posted</div>
                                <div className="job__info-list__item__text"><JobDate jobData={data.strapiJobs} /></div>
                            </div>
                            }
                        </div>

                        {data.strapiJobs.description &&
                            <OnlyArticleTextHTML html={data.strapiJobs.description} sectionClass=" mt-50 mb-28 mb-md-51 mt-lg-47 mb-lg-100" />
                        }
                    </Col>
                    {data.strapiTeams &&
                        <ContactSidebar
                            moduleClass=" module-contact-sidebar col-xs-12 offset-xl-1 col-xl-4 mt-40 mt-md-60 mt-xl-6"
                            btnClass="btn btn-primary btn-block btn-primary-no-shadow mb-20"
                            btnClass2="btn btn-outline-secondary btn-block"
                            hideModuleBg={true}
                            membersData={[data.strapiTeams]}
                            sidebarData={sidebarData[0]}
                            pageLocation={location}
                            pageTitle={data.strapiJobs.title}
                        />
                    }
                </Row>
            </Container>
            <ImageTextModule
                imgUrl={data.textModuleImg.childImageSharp.fluid}
            />
            <PopularSearch title={data.strapiJobs.title} {...props}/>
            <Footer />
        </Layout>
    );
}

export default JobPages

export const query = graphql`
query JobQuery($id: Int!, $memberName: String!) {
    strapiJobs(jobid: {eq: $id}, status: {eq: true}) {
        id
        createdAt
        currency
        job_type
        jobid
        salary
        ote
        title
        websitecountry
        status
        description
        slug
        clientContact {
            id
            firstName
            lastName
        }
        address {
            countryID
            countryName
            countryCode
            city
            address2
            address1
            state
            zip
        }
    }

    strapiTeams(Name: {eq: $memberName}) {
        id
        Name
        PhoneNumber
        CalendlyLink
        BookVideoLabel
        Image {
            childImageSharp {
                fixed(width: 70, height: 70) {
                    ...GatsbyImageSharpFixed_withWebp_noBase64
                }
            }
        }
    }
    sidebarImg: file(relativePath: { eq: "young-woman.jpg" }) {
        childImageSharp {
            fluid(quality: 80, maxWidth: 400) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
            }
        }
    }
    textModuleImg: file(relativePath: { eq: "our-client-get-started-tile.png" }) {
        childImageSharp {
            fluid(quality: 80, maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
            }
        }
    }
}
`